import React from "react";
import Heading from "../atoms/Heading";
import Image from "next/image";
import Text from "../atoms/Text";
import LinkButton from "../atoms/LinkButton";
const Category = ({ categories, router }) => {
  return (
    <div className={"my-4 md:my-8 relative"}>
      <div className="flex justify-center items-center mb-4 md:mb-8">
        <Heading
          type="h2"
          className="uppercase !text-3xl md:!text-6xl text-center font-bold text-primary-900"
        >
          Categories
        </Heading>

        <LinkButton
          href={"/categories"}
          color="text-primary-900"
          className="font-medium absolute top-4 right-0 lg:block hidden text-sm md:text-xl border-b border-primary-900 pb-0"
        >
          View All
        </LinkButton>
      </div>
      <div className={"my-4 relative"}>
        <div className="md:grid hidden gap-[13px] my-4 relative md:grid-cols-4 grid-cols-2">
          {categories.slice(0, 8).map((categorie) => {
            return (
              <>
                <div
                  className="relative overflow-hidden rounded-xl h-32 grid md:grid-cols-4 grid-cols-2 cursor-pointer"
                  onClick={() => {
                    router.push("/categories");
                  }}
                >
                  <Image
                    src={
                      categorie.image ||
                      "https://kashkounter-prod.s3.ap-southeast-1.amazonaws.com/Default.svg"
                    }
                    layout="fill"
                    objectFit="cover"
                  />
                  <div className="absolute bg-black bg-opacity-40 top-0 font-Nunito w-full h-full text-white flex items-center justify-center"></div>
                  <div className="absolute top-0 left-0 font-Nunito w-full h-full text-white flex items-center justify-center z-0 px-6">
                    <Text className="text-white text-2xl font-semibold text-center">
                      {categorie.name}
                    </Text>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="md:hidden grid gap-[13px] my-4 relative md:grid-cols-4 grid-cols-2">
          {categories.slice(0, 6).map((categorie) => {
            return (
              <>
                <div
                  className="relative overflow-hidden rounded-xl h-32 grid md:grid-cols-4 grid-cols-2"
                  onClick={() => {
                    router.push("/categories");
                  }}
                >
                  <Image
                    src={
                      categorie.mobileImage ||
                      "https://kashkounter-prod.s3.ap-southeast-1.amazonaws.com/Default-mobile.svg"
                    }
                    layout="fill"
                    objectFit="cover"
                  />
                  <div className="absolute bg-black bg-opacity-40 top-0 font-Nunito w-full h-full text-white flex items-center justify-center"></div>
                  <div className="absolute top-0 left-0 font-Nunito w-full h-full text-white flex items-center justify-center z-0 px-6">
                    <Text className="text-white text-2xl font-semibold text-center">
                      {categorie.name}
                    </Text>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className="text-center">
        <LinkButton
          href={"/categories"}
          color="text-primary-900"
          className="font-medium lg:hidden inline-block  text-sm md:text-xl border-b border-primary-900 pb-0"
        >
          View All
        </LinkButton>
      </div>
    </div>
  );
};
export default Category;
