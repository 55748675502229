import { Swiper } from "swiper/react";
// Import Swiper styles
import IcomoonIcon from "../atoms/IcomoonIcon";
import { useCallback, useRef, useState } from "react";
import { A11y } from "swiper";

export default function CashbackSimplifiedCarousel({ children, ...property }) {
    const sliderRef = useRef(null);
    const [isBegin, setIsBegin] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [slideState, setSlideState] = useState(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        setIsBegin(sliderRef.current.swiper.isBeginning);
        setIsEnd(sliderRef.current.swiper.isEnd);
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        setIsBegin(sliderRef.current.swiper.isBeginning);
        setIsEnd(sliderRef.current.swiper.isEnd);
    }, []);
    return (
        <>
            <div className={`flex items-center md:-mr-0 ${property.className}`}>
                <Swiper
                    watchOverflow
                    onSwiper={setSlideState}
                    onSlideChange={(e) => {
                        setIsEnd(e.isEnd);
                        setIsBegin(e.isBeginning);
                    }}
                    ref={sliderRef}
                    observer
                    observeParents
                    slidesPerView={"auto"}
                    spaceBetween={30}
                    loopFillGroupWithBlank={false}
                    className="mySwiper"
                    modules={[A11y]}
                    breakpoints={{
                        300: {
                            spaceBetween: 15,
                        },
                        500: {
                            spaceBetween: 30,
                        },
                    }}
                >
                    {children}
                </Swiper>
            </div>

            <div className="w-full flex items-center space-x-6 mt-2 md:mt-6 justify-center lg:hidden">
                <div>
                    {sliderRef?.current?.swiper?.width !== sliderRef?.current?.swiper?.virtualSize && (
                        <div onClick={handlePrev} className={`cursor-pointer flex justify-center items-center rounded-full w-8 h-8 ${isBegin ? "bg-neutral-300" : "bg-primary-900"}`}>
                            <IcomoonIcon icon={"arrow-right"} className="-rotate-180" color={`${isBegin ? "white" : "#fff"}`} size="20px" />
                        </div>
                    )}
                </div>

                <div>
                    {sliderRef?.current?.swiper?.width !== sliderRef?.current?.swiper?.virtualSize && (
                        <div onClick={handleNext} className={`cursor-pointer flex justify-center items-center rounded-full w-8 h-8  ${isEnd ? "bg-neutral-300" : "bg-primary-900"}`}>
                            <IcomoonIcon icon={"arrow-right"} color={`${isEnd ? "white" : "#fff"}`} size="20px" />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
