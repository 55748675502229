import { Swiper } from "swiper/react";
import { useRef } from "react";
import { A11y, Autoplay, Pagination } from "swiper";
export default function HomeHeaderCarousel({ children, ...property }) {
    const sliderRef = useRef(null);
    return (
        <div className={`w-11/12 lg:max-w-[1200px] mx-auto relative pt-2 ${property.className}`}>
            <div className={`w-full flex items-center md:-mr-0 `}>
                <Swiper
                    watchOverflow
                    ref={sliderRef}
                    observer
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    observeParents
                    slidesPerView={1}
                    spaceBetween={0}
                    loopFillGroupWithBlank={false}
                    pagination={{ clickable: true }}
                    className="mySwiper"
                    modules={[A11y, Autoplay, Pagination]}
                >
                    {children}
                </Swiper>
            </div>
        </div>
    );
}
