import Container from "../atoms/Container";
import Heading from "../atoms/Heading";
import LinkButton from "../atoms/LinkButton";
import MerchantCard from "../molecules/MerchantCard";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

function HomeBannerPostLogin({ name, topStoresInfo }) {
    const router = useRouter();
    return (
        <div className="relative">
            <Image src="/images/postLoginBG.svg" layout="fill" objectFit="contain" objectPosition="top left" />
            <Container type='type0' className="relative z-10 pt-9 md:pt-32 pb-10">
                <div className="relative z-10">
                    <div className="">
                        <div className="text-center md:text-left">
                            <Heading
                                type="h2"
                                className="max-w-5xl flex flex-col md:flex-row flex-wrap md:flex-nowrap items-center justify-center md:justify-start gap-1.5 md:gap-3 text-3xl md:text-6xl font-bold text-black mb-4 md:mb-2 md:!leading-[1.5]"
                            >
                                Hey
                                <div className="md:truncate inline-flex items-center text-primary-900 gap-3">
                                    <span className="md:truncate">{name}</span>
                                    <div className="flex-none w-8 h-8 md:w-[60px] md:h-[60px] relative">
                                        <Image src="/images/celeb1.png" layout="fill" objectFit="contain" />
                                    </div>
                                </div>
                            </Heading>
                            <Heading type="h4" className="text-base md:text-2xl font-semibold text-tirtiary-900 mb-6 md:mb-0">
                                It's great to have you on <span className="text-primary-900">KashKounter</span> <br /> Now let's go earn that cashback!
                            </Heading>
                        </div>
                    </div>
                    <div className="relative grid md:grid-cols-4 gap-2.5 mt-12  ">
                        {topStoresInfo.slice(0, 8).map((info, index) => (
                            <MerchantCard
                                onClick={() => router.push(`/merchants/${info.name}/${info.id}`)}
                                id={info.id}
                                key={info.id}
                                image={info.logo}
                                cashbackPercentage={`Up to ${info.cashback / 100}% cashback`}
                                time={info.time}
                                name={info.name}
                                compactView={true}
                                index={index}
                            />
                        ))}
                    </div>
                    <div className="block md:hidden text-center mt-3 md:mt-6">
                        <LinkButton href={"/categories"} color="text-primary-900" className="font-medium text-sm md:text-xl border-b border-primary-900 pb-0">
                            View All
                        </LinkButton>
                    </div>
                </div>
                <div className="hidden md:block absolute right-6 bottom-0">
                    <Image src="/images/homePost.svg" width={530} height={590} objectFit="contain" objectPosition="bottom right" />
                </div>
            </Container>
        </div>
    );
}

export default HomeBannerPostLogin;
