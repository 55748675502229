import { getAuth } from "services/identity.service";
import { useRouter } from "next/router";
import { getBannerImages, getActiveBanners } from "../services/banner.service";
import { handleApiResponse } from "../services/helper-function.service";
import { getAllMerchants } from "../services/merchant.service";
import { getReferee, createReferee, getReferralCode, getUser, updateReferralOptIn } from "../services/user.service";
import { event } from "../services/gtag.service";
import { useEffect, useState } from "react";
import HomeTemplate from "../templates/HomeTemplate";
import { getAllCategories } from "services/category.service";


const fetchImages = async (offset, limit, search, status, name, availableForAll, order, startDate, endDate) => {
    const bannerType = 'hero';
    const res = await getBannerImages(offset, limit, search, status, name, availableForAll, order, startDate, endDate, bannerType);
    const { details: images, apiError } = handleApiResponse(res);
    return { images, apiError };
};

const fetchUser = async (userId, token) => {
    const res = await getUser(userId, token);
    const { details: user, apiError } = handleApiResponse(res);
    return { user, apiError };
};

const fetchReferee = async (userId, token) => {
    const res = await getReferee(userId, token);
    const { details: referee, apiError } = handleApiResponse(res);
    return { referee, apiError };
};

const fetchAllMerchants = async () => {
    const res = await getAllMerchants();
    const { details: allMerchants, apiError } = handleApiResponse(res);
    return { allMerchants, apiError };
};

export default function Home({ images, apiErrors, allMerchants, refereeDetails, userDetails , activeBanners, allCategories}) {
    const [messagePopup, setMessagePopup] = useState("");
    const [type, setType] = useState("");
    const router = useRouter();
    const [auth, setAuth] = useState(null);
    const [showReferralDialog, setShowReferralDialog] = useState(false);

    useEffect(() => {
        setAuth(getAuth());
    }, []);

    useEffect(() => {
        setShowReferralDialog((prev) => auth && auth.newUser && !Object.entries(refereeDetails).length > 0 && userDetails?.referralOptin);
    }, [auth]);

    auth &&
        event(
            {
                action: "login-successful",
            },
            {
                "user-id": auth?.id,
                email: auth?.email,
                status: "success",
                "login-type": auth?.loginStrategy,
                "new-user": auth?.newUser ? "yes" : "no",
            }
        );

    if (auth?.newUser) {
        event(
            {
                action: "new-users",
            },
            {
                "btn-clicked": "yes",
                "login-type": auth?.loginStrategy,
                "new-user": auth?.newUser ? "yes" : "no",
            }
        );
    }

    const loginHandler = (url) => {
        const { title } = images?.rows.filter((image) => image.destinationLink === url)[0];
        event(
            {
                action: "banner-clicked",
            },
            {
                "user-id": auth?.id,
                email: auth?.email,
                "banner-title": title || "",
                "banner-page-url": url,
                "status(success/failure)": "success",
            }
        );
        if (url) return router.push(url);
        return router.push("/categories");
    };
    const homeHeader = images.rows.map((image) => ({ srcImg: image.desktopUrl, link: image?.destinationLink }));
    const mobileHomeHeader = images.rows.map((image) => ({ srcImg: image.mobileUrl, link: image?.destinationLink }));

    const createRefereeDetails = async (details) => {
        const response = await createReferee({ ...details, userId: auth.id });
        if (response?.status) {
            setType("success");
            setMessagePopup("Code applied successfully! Bonus has been added to your Wallet!");
        } else {
            setType("failure");
            setMessagePopup(response?.message);
        }
        setShowReferralDialog(false);
        setTimeout(() => {
            setType("");
            setMessagePopup("");
        }, 5000);
    };

    const referralCode = async (code) => {
        const res = await getReferralCode(code, auth.token);
        if (!res.status) {
            console.error("Error", res.message);
        }
        return res;
    };
    const onReferralDialogueClose = async () => {
        const res = await updateReferralOptIn(userDetails.id, { referralOptin: false });
        if (res.status) {
            setShowReferralDialog(false);
        }
        if (!res.status) {
            console.error("Error", res.message);
        }
        return res;
    };
    return (
        <HomeTemplate
            auth={auth}
            loginHandler={loginHandler}
            homeHeader={homeHeader}
            mobileHomeHeader={mobileHomeHeader}
            allMerchants={allMerchants}
            createRefereeDetails={createRefereeDetails}
            referralCode={referralCode}
            showReferralDialog={showReferralDialog}
            setShowReferralDialog={setShowReferralDialog}
            messagePopup={messagePopup}
            setMessagePopup={setMessagePopup}
            type={type}
            onReferralDialogueClose={onReferralDialogueClose}
            activeBanners={activeBanners}
            categories={allCategories}
        />
    );
}
export const getServerSideProps = async ({ req }) => {
    const auth = req.cookies.AUTH && JSON.parse(req.cookies.AUTH);
    let availableForAll = true;
    let allCategories= [];
    let activeBanners = [];
    // if (auth) {
    //     availableForAll = "";
    // }
    let refereeDetails = {};
    let userDetails = {};
    if (auth) {
        let { referee } = await fetchReferee(auth.id, auth.token);
        refereeDetails = referee;
        let { user } = await fetchUser(auth.id, auth.token);
        userDetails = user;
    }
    

    const fetcherCategories = async (name) => {
        const res = await getAllCategories(name);
        const { details, apiError } = await handleApiResponse(res);
        return { categories: details };
    };

    const banners = await getActiveBanners();
       if(banners.status){
        activeBanners = banners.entity;
       }

      const  {categories,}= await fetcherCategories("");
    
      allCategories = categories
    const { images, apiError } = await fetchImages(0, 10, "", true, "createdAt", availableForAll, "DESC", "");
    
    const { allMerchants } = await fetchAllMerchants();
    return { props: { allMerchants, refereeDetails, userDetails, images, activeBanners, allCategories} } 
};
