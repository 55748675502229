import CashbackStepCard from "../molecules/CashbackStepCard";
import React from "react";
import { SwiperSlide } from "swiper/react";
import CashbackSimplifiedCarousel from "./CashbackSimplifiedCarousel";
import CashbackStepsDesktop from "./CashbackStepsDesktop";
import Image from "next/image";
import Container from "../atoms/Container";
import Heading from "../atoms/Heading";

const CashbackSimplified = ({ cashbackSteps }) => {
  return (
    <div id="howItWorks" className="py-6 md:py-8 relative">
      <Image
        src="/images/how-bg.png"
        layout="fill"
        objectFit="contain"
        objectPosition="top"
      />
      <Container
        type="type0"
        className="relative z-10 flex flex-wrap md:items-end justify-between gap-10"
      >
        <div className="flex-none hidden xl:flex items-end absolute">
          <Image
            src="/images/HowItWorks1.png"
            width={550}
            height={550}
            objectFit="contain"
            objectPosition="bottom"
          />
        </div>
        <div className="flex-1 w-full md:w-fit flex flex-col items-center xl:items-end">
          <div className="text-center xl:text-right max-w-2xl mb-4 md:mb-10">
            <Heading
              type="h2"
              className="!text-3xl md:!text-6xl font-bold text-primary-900 mb-2 md:mb-4"
            >
              HOW IT WORKS
            </Heading>
            <Heading
              type="h4"
              className="text-lg md:text-2xl font-semibold text-tirtiary-900 mb-1.5"
            >
              It’s easier than you expect. Every time you shop via KashKounter,
              brands pay us and we pay you.
            </Heading>
            <Heading
              type="h4"
              className="text-lg md:text-2xl font-semibold text-primary-900"
            >
              It’s that simple!
            </Heading>
          </div>
          <CashbackStepsDesktop
            cashbackSteps={cashbackSteps}
            className="lg:flex hidden"
          />
          <CashbackSimplifiedCarousel className="w-full lg:hidden">
            {cashbackSteps.map((info, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <CashbackStepCard
                  title={info.title}
                  stepNumber={info.stepNumber}
                  description={info.description}
                  image={info.image}
                />
              </SwiperSlide>
            ))}
          </CashbackSimplifiedCarousel>
        </div>
      </Container>
    </div>
  );
};
export default CashbackSimplified;
