import PropTypes from "prop-types";
import Text from "../atoms/Text";
import IcomoonIcon from "../atoms/IcomoonIcon";

const toasterType = {
    success: "success",
    failed: "failed",
};

const Toaster = ({
    color,
    children,
    onClick,
    icon,
    size,
    show,
    setShow,
    type,
    close = false,
    ...property
}) => {
    if (!show) return null;
    return (
        <div
            type={toasterType}
            className={`fixed max-w-[1158px] left-0 right-0 mx-auto px-6 top-[62px] md:top-[85px] z-50 overflow-hidden z-[2000] ${property.className}`}
            onClick={onClick}
        >
            <div
                className={`max-w-[700px] w-fit ml-auto duration-1000 transition-all ease-out flex items-center space-x-3 px-4 py-4 text-white rounded ${type === "success"
                    ? "bg-primary-900"
                    : "bg-error-100"
                    } ${show ? "translate-x-0" : "translate-x-[105%]"} `}
            >
                <IcomoonIcon size={size} icon={icon} />
                <Text variant="bodySmall" className="text-xs md:text-sm">
                    {children}
                </Text>
                {
                    close && (
                        <IcomoonIcon
                            size={'10'}
                            icon='close-b'
                            color={type === 'success' ? '#fff' : '#DC2020'}
                            onClick={close}
                            className='cursor-pointer flex-none'
                        />
                    )
                }
            </div>
        </div>
    );
};

export default Toaster;

Toaster.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
