import Heading from "../atoms/Heading";
import Text from "../atoms/Text";
import Image from "next/image";
import React from "react";

const CashbackStepCard = ({ image, title, description, stepNumber, ...property }) => {
    return (
        <div className={`bg-secondary-900 rounded-[40px] py-6 md:py-8 px-6 md:px-16 flex flex-col items-center relative ${property.className}`}>
           <Image src={image} width={45} height={45} />
           <Heading type="h4" className="text-lg text-primary-900 font-bold mt-3 mb-2">{title}</Heading>
              <Text className="text-xs text-neutral-700">{description}</Text>
        </div>
    );
};

export default CashbackStepCard;
