import CashbackSimplified from "../organisms/CashbackSimplified";
import TopStores from "../organisms/TopStores";
import React, { useEffect, useRef, useState } from "react";
import Head from "next/head";
import HomeBanner from "../organisms/HomeBanner";
import HomeBannerPostLogin from "../organisms/HomeBannerPostLogin";
import { useRouter } from "next/router";
import ReferralDialog from "../organisms/Referral/ReferralDialog";
import Toaster from "../molecules/Toaster";
import HomeHeaderCarousel from "../organisms/HomeHeaderCarousel";
import { SwiperSlide } from "swiper/react";
import Image from "next/image";
import Container from "../atoms/Container";
import { getActiveBanners } from "../services/banner.service";

const HomeTemplate = ({
    auth,
    allMerchants,
    createRefereeDetails,
    referralCode,
    showReferralDialog,
    messagePopup,
    setMessagePopup,
    type,
    onReferralDialogueClose,
    homeHeader = [],
    mobileHomeHeader = [],
    loginHandler,
    activeBanners,
    categories,
}) => {
    const [footerBanner, setFooterBanner] = useState();

    const cashbackSteps = [
        {
            id: 1,
            image: "/images/lock.svg",
            title: "Hang on..  what’s the catch?",
            description: "There’s no catch! When you shop through KashKounter, we earn a commission from those brands and share it with you.",
        },
        {
            id: "2",
            image: "/images/secure.svg",
            title: "Are my details secure?",
            description: "Any information you share with KashKounter is stored securely using the latest encryption technology",
        },
        {
            id: "3",
            image: "/images/money.svg",
            title: "How do I receive my cashback",
            description: `Once your cashback is approved by a brand, we will pay it directly into your KashKounter account`,
        },
    ];

    const router = useRouter();
    const howRef = useRef();

    const getFooterBanner = async () => {
        const banners = await getActiveBanners();

        if (banners.status && banners.entity.length) {
            setFooterBanner(banners.entity.filter((banner) => banner.bannerType === "Footer Banner" && banner.status === true)[0]);
        }
    };

    useEffect(() => {
        getFooterBanner();
    }, []);

    useEffect(() => {
        if (router.asPath === "/#howItWorks") {
            const sectionPosition = howRef.current.offsetTop - 80;
            window.scrollTo({
                top: sectionPosition,
                behavior: "smooth",
            });
        }
    }, []);

    return (
        <>
            <Head>
                <title>KashKounter - Never pay full price again. Shop, save and earn money on every purchase.</title>
                <meta
                    name="description"
                    content="Start shopping smarter with KashKounter - South-Asia’s leading cashback platform. Earn cashback on top of discount coupons, promo codes, vouchers and brand promotions!"
                />
            </Head>

            <>
                <HomeHeaderCarousel className="justify-center hidden md:block mt-8">
                    <div className="hidden md:block">
                        {homeHeader.map((image, index) => (
                            <SwiperSlide key={index} className="flex justify-center cursor-pointer" onClick={() => loginHandler(image.link)}>
                                <Image data-testid={`banner-desk-${index}`} src={image.srcImg} height="418" width={"1200"} />
                            </SwiperSlide>
                        ))}
                    </div>
                </HomeHeaderCarousel>
                <HomeHeaderCarousel className="justify-center md:hidden">
                    <div className="hidden lg:block">
                        {mobileHomeHeader.map((image, index) => (
                            <SwiperSlide key={index} className="flex justify-center" onClick={() => loginHandler(image.link)}>
                                <Image data-testid={`banner-mob-${index}`} src={image.srcImg} height="208" width={"366"} />
                            </SwiperSlide>
                        ))}
                    </div>
                </HomeHeaderCarousel>{" "}
                <TopStores topStoresInfo={allMerchants} activeBanners={activeBanners} categories={categories} />
            </>

            <Toaster show={messagePopup.length ? true : false} type={type} children={messagePopup} close={() => setMessagePopup("")} />
            <div ref={howRef}>
                <CashbackSimplified cashbackSteps={cashbackSteps} />
            </div>
            {auth?.name && (
                <ReferralDialog
                    title="Got a Referral Code?"
                    show={showReferralDialog}
                    close={() => {
                        onReferralDialogueClose();
                    }}
                    referralCode={referralCode}
                    createRefereeDetails={createRefereeDetails}
                />
            )}

            {footerBanner && (
                <Container type="type0" className="mb-8 cursor-pointer" onClick={() => footerBanner.destinationLink && router.push(footerBanner.destinationLink)}>
                    <div id="footerId" className="relative h-44 w-full hidden md:block">
                        <Image src={`${footerBanner?.desktopUrl}`} layout="fill" width="1200px" height="180px" />
                    </div>
                    <div className="relative h-40 -mt-2 w-full block md:hidden">
                        <Image src={`${footerBanner?.mobileUrl}`} layout="fill" width="366" height="160" />
                    </div>
                </Container>
            )}
        </>
    );
};

export default HomeTemplate;
