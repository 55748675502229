import MerchantCard from "../molecules/MerchantCard";
import Image from "next/image";
import React from "react";
import { useRouter } from "next/router";
import Heading from "../atoms/Heading";
import Container from "../atoms/Container";
import LinkButton from "../atoms/LinkButton";
import Category from "../molecules/Category";

const TopStores = ({ topStoresInfo, activeBanners, categories }) => {
  const router = useRouter();

  const midBanner = activeBanners.filter(
    (banner) => banner.bannerType === "Mid Banner" && banner.status === true
  );
  const middleBanner = midBanner[0];
  const midBannerSrc = middleBanner?.desktopUrl;
  const midBannerMobile = middleBanner?.mobileUrl;

  return (
    <Container type="type0" className="relative pb-0 md:pb-8">
      {middleBanner && (
        <div className="-mb-2 cursor-pointer">
          <div className="hidden md:block">
            <Image
              src={midBannerSrc}
              className="w-full h-44 cursor-pointer"
              onClick={() => router.push(`${midBanner[0]?.destinationLink}`)}
              width={"1200"}
              height={"180"}
            />
          </div>
          <div className="block md:hidden">
            <Image
              src={midBannerMobile}
              className="w-full h-44 cursor-pointer"
              onClick={() => router.push(`${midBanner[0]?.destinationLink}`)}
              width={"360"}
              height={"160"}
            />
          </div>
        </div>
      )}

      <div className="">
        <Category categories={categories} router={router} />
      </div>
      <div className="relative z-10 w-full mt-6 md:mt-0">
        <Heading
          type="h2"
          className="text-center !text-3xl md:!text-6xl font-bold text-primary-900 mb-4 md:mb-8"
        >
          TOP BRANDS
        </Heading>
        <div className="relative grid md:grid-cols-4 gap-2.5">
          {topStoresInfo.slice(0, 8).map((info, index) => (
            <MerchantCard
              onClick={() => router.push(`/merchants/${info.name}/${info.id}`)}
              id={info.id}
              key={info.id}
              image={info.logo}
              cashbackPercentage={`Up to ${info.cashback / 100}% cashback`}
              time={info.time}
              name={info.name}
            />
          ))}
        </div>
        <div className="text-center mt-3 md:mt-6 ">
          <LinkButton
            href={"/categories"}
            color="text-primary-900"
            className="font-medium text-sm md:text-xl border-b border-primary-900 pb-0"
          >
            View All
          </LinkButton>
        </div>
      </div>
    </Container>
  );
};
export default TopStores;
