import Image from "next/image";
import React from "react";

function BrandLogoCard({ logo, type = "large", border , className }) {
  const size = {
    large:
      "w-[66px] md:w-[98px] h-[66px] md:md:h-[98px] rounded-xl md:rounded-2xl",
    small: "w-[66px] h-[66px] rounded-xl",
  };

  return (
    <div className={`border bg-white p-2 ${size[type]} ${border} ${className}`}>
      <div className="relative w-full h-full">
        <Image
          src={logo}
          layout="fill"
          objectFit="contain"
          objectPosition="center"
        />
      </div>
    </div>
  );
}

export default BrandLogoCard;
