import Heading from "../atoms/Heading";
import Text from "../atoms/Text";
import Image from "next/image";
import React from "react";

const CashbackStepsDesktop = ({ cashbackSteps, ...property }) => {
  return (
    <div className={`bg-secondary-900 rounded-[40px] p-12 max-w-2xl flex gap-10 ${property.className}`}>
      {
        cashbackSteps.map(step => {
          return (
            <div key={step.id}>
              <div className="text-center mb-2">
                <Image
                  src={step.image}
                  width={35}
                  height={35}
                />
              </div>
              <Heading type="h4" className="text-lg text-primary-900 font-bold mb-2">{step.title}</Heading>
              <Text className="text-xs text-neutral-700">{step.description}</Text>
            </div>
          )
        })
      }
    </div>
  );
};

export default CashbackStepsDesktop;
