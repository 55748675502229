import Text from "../atoms/Text";
import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import BrandLogoCard from "../atoms/BrandLogoCard";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";

const MerchantCard = ({ image, id, cashbackPercentage, name, time, onClick, compactView = false, index, ...property }) => {
    const router = useRouter();
    return (
        <div className={`w-full bg-white border border-neutral-300 rounded-xl p-3 cursor-pointer group hover:shadow-basic transition ${(compactView && index > 3) ? 'hidden md:block' : '' } ${property.className}`} onClick={onClick}>
            <div className="flex items-center gap-2 text-left">
                <BrandLogoCard type="small" logo={image} />
                <div>
                    <Heading type="h4" className="text-lg font-semibold mb-1.5">{name}</Heading>
                    <Text fontWeight="font-medium" className="text-primary-900 text-xs">
                        {cashbackPercentage}
                    </Text>
                </div>
            </div>
            {
                !compactView && (
                    <Button
                        style="ghost"
                        label="View Brand"
                        className="hidden md:block rounded-full !font-normal w-full mt-4 transition group-hover:bg-primary-900 group-hover:text-white"
                        size="small"
                    />
                )
            }

        </div>
    );
};

export default MerchantCard;

MerchantCard.propTypes = {
    image: PropTypes.string,
    cashbackPercentage: PropTypes.string,
    time: PropTypes.string,
};
