import * as HttpService from "./http.service";
import { GET_BANNER_IMAGES_URL, GET_ACTIVE_BANNER_URL, GET_BANNER_IMAGE_URL } from "./url.service";

export const getBannerImages = (offset, limit, search, status, name, availableForAll, order, startDate, endDate, bannerType) => {
    return HttpService.getWithOutAuth(GET_BANNER_IMAGES_URL(offset, limit, search, status, name, availableForAll, order, startDate, endDate, bannerType));
};

export const getActiveBanners = () => {
    return HttpService.getWithOutAuth(GET_ACTIVE_BANNER_URL());
};
export const getBannerImage = (id, token) => {
    return HttpService.getWithAuth(GET_BANNER_IMAGE_URL(id), token);
};
