import IcomoonIcon from "../../atoms/IcomoonIcon";
import Heading from "../../atoms/Heading";
import useClickOutside from "./ClickOutside";
import { useRef } from "react";

const DialogBox = ({ children, title, titleStyle, width = 'w-max', height = 'h-max', onClick = () => true, close = () => true, iconSize = 'w-6 h-6', radius = 'rounded-2xl', ...props }) => {
    const dialogRef = useRef();
    useClickOutside(dialogRef, close);
    return (
        <div className={`dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-black bg-opacity-70 z-[20000] px-4 md:px-0 ${props.className}`}>
            <div ref={dialogRef} className={`bg-white h-auto py-5 px-4 md:px-6 ${radius} ${width} ${height} `}>
                <div className="dialog-header flex items-center justify-between gap-10">
                    {
                        title && (
                            <Heading type='h6' className={`text-neutral-900 font-semibold ${titleStyle}`}>{title}</Heading>
                        )
                    }
                    <div className={`ml-auto bg-primary-900 rounded-full ${iconSize} flex items-center justify-center cursor-pointer`} onClick={close}>
                        <IcomoonIcon
                            className=""
                            icon={'close-b'}
                            size={'12'}
                            color='#fff'
                        />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

export default DialogBox;