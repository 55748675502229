import { yupValidator } from "../../components/Utilities/yup-validator";
import DialogBox from "../../components/common/DialogBox";
import Button from "../../atoms/Button";
import InputBox from "../../atoms/InputBox";
import Text from "../../atoms/Text";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

function ReferralDialog({ show = false, close, referralCode, createRefereeDetails }) {
    if (!show) return null;

    const [isValidCode, setIsValidCode] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setError,
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                code: yup.string(),
            })
        ),
    });

    const checkReferralCode = async (data) => {
        const result = await referralCode(data.code);
        if (result.status && result.entity) setIsValidCode(true);
        else
            setError("code", {
                type: "manual",
                message: "Invalid Code",
            });
    };

    const onSubmit = async (data) => {
        await createRefereeDetails(data);
    };

    useEffect(() => {
        isValidCode && setIsValidCode(false) 
    }, [watch("code")])

    return (
        <DialogBox title="Got a Referral Code?" width="w-full max-w-md" close={close} titleStyle="!text-2xl" iconSize="w-8 h-8" radius="rounded-3xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative my-6">
                    <InputBox
                        id="code"
                        name="code"
                        dbName="code"
                        placeholder="Enter Referral Code "
                        register={register}
                        type="text"
                        variant="Large"
                        isError={!!errors?.code}
                        errorMessage={errors?.code?.message}
                        errorClass="block"
                    />
                    <div className="flex items-center absolute top-6 transform -translate-y-1/2 right-4">
                        {isValidCode ? (
                            <Image src="/images/checked.svg" width={20} height={20} />
                        ) : (
                            <Text
                                variant="bodySmall"
                                className={` font-semibold ${watch("code") ? "text-primary-900 cursor-pointer" : "text-primary-300 cursor-not-allowed"}`}
                                onClick={handleSubmit((data) => checkReferralCode(data))}
                            >
                                Check
                            </Text>
                        )}
                    </div>
                </div>
                <Button label="Apply" className="w-full rounded-full" disabled={!isValidCode} onClick={handleSubmit((data) => onSubmit(data))} />
            </form>
        </DialogBox>
    );
}

export default ReferralDialog;
